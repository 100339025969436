var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{attrs:{"to":"modal"}},[_c('b-modal',{attrs:{"active":_vm.active,"can-cancel":false}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
var valid = ref.valid;
return [_c('form',{staticClass:"modal-card",on:{"submit":function($event){$event.preventDefault();return passes(_vm.save)}}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(" Create a new blog post ")])]),_c('section',{staticClass:"modal-card-body"},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"title","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Title","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading,"expanded":""},model:{value:(_vm.blogPost.title),callback:function ($$v) {_vm.$set(_vm.blogPost, "title", $$v)},expression:"blogPost.title"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"text","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Text","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('editor',{attrs:{"api-key":_vm.apiKey,"disabled":_vm.isLoading},model:{value:(_vm.blogPost.text),callback:function ($$v) {_vm.$set(_vm.blogPost, "text", $$v)},expression:"blogPost.text"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"category","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Category","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('sp-blog-category',{attrs:{"append-to-body":true,"disabled":_vm.isLoading},model:{value:(_vm.blogPost.category),callback:function ($$v) {_vm.$set(_vm.blogPost, "category", $$v)},expression:"blogPost.category"}})],1)]}}],null,true)})],1),_c('footer',{staticClass:"modal-card-foot"},[_c('sp-link',{attrs:{"tag":"b-button","disabled":_vm.isLoading,"to":{name: 'infringements.list'},"label":_vm.$t('close'),"icon-left":"times"}}),_c('b-button',{attrs:{"disabled":! valid || _vm.isLoading,"label":_vm.$t('create'),"icon-left":"save","type":"is-primary","native-type":"submit"}})],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }